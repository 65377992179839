import React from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getUserTooltip } from '@webfx/teamwork-web/src/ui/components/UserOverlayTooltip';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Button } from '@webfx/core-web';
import { useUserRoles, useRouter, useCanViewAs } from '@webfx/web-hooks';

import Avatar from '../Avatar/Avatar';
import { Card, CardTitle, CardInfo, CardEditLink, CardSubTitle } from '../Card';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import EmailAddress from '../EmailAddress/EmailAddress';
import Checkbox from '../Checkbox/Checkbox';
import Icon from '../Icon';
import Svg from '../Svg/Svg';
import iconNotificationAlertSvg from '../../assets/images/icon-notification-alert.svg';
import styles from './UserCard.module.css';

/**
 *
 * @param {object }root0
 * @param {object} root0.user
 * @param {boolean} root0.editable
 * @param {string} root0.className
 * @param {React.children} root0.children
 * @param {boolean} root0.showToggles
 * @returns {React.ReactElement}
 */
const UserCard = ({
  showToggles,
  companyUser,
  editable,
  className,
  usersClasses,
  children,
  extendedTooltip = false,
  onViewAs,
}) => {
  const { url } = useRouter();
  const { onMutate, roles } = useUserRoles();
  const user = companyUser?.user;
  const canViewAs = useCanViewAs({...user, active: companyUser?.active, companiesUsers: [companyUser.company]});
  if (!user) {
    return null;
  }

  const { userId, avatarUrl, firstName, lastName, title, officeNumber, email, lastActiveAt } = user;
  const tooltip = extendedTooltip ? getUserTooltip(user) : `${firstName} ${lastName}`;
  const isActive = companyUser?.active;
  const { lastActiveUpdatedByUser, lastActiveUpdatedAt, lastReportSent } = companyUser;
  const { firstName: updatedByFirstName, lastName: updatedByLastName } =
    lastActiveUpdatedByUser || {};
  let lastActiveUpdatedByUserName = null;
  if (lastActiveUpdatedByUser) {
    lastActiveUpdatedByUserName = `${updatedByFirstName} ${updatedByLastName}`;
  }

  let lastActiveUpdatedAtDate = null;
  if (lastActiveUpdatedAt) {
    lastActiveUpdatedAtDate = DateTime.fromISO(lastActiveUpdatedAt).toFormat('MMM dd, yyyy');
  }
  const fullName = _.startCase(_.toLower(`${firstName} ${lastName}`));

  return (
    <Card
      className={classnames(
        {
          [styles.inactive]: !isActive,
        },
        className
      )}
      data-fx-name="userCard"
    >
      <Card.Body className="position-relative">
        {showToggles ? (
          <div className={usersClasses.activeCheckbox}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip data-fx-name="tooltip">{isActive ? 'Active' : 'Inactive'}</Tooltip>}
            >
              <div>
                <Checkbox
                  id={`user-${userId}-active`}
                  key={`user-${userId}-active`}
                  checked={isActive}
                  onChange={() => onMutate('active', companyUser)}
                  customSwitch
                  size="lg"
                  name="Active"
                  data-fx-name={_.camelCase(isActive ? 'Active' : 'Inactive')}
                />
              </div>
            </OverlayTrigger>
          </div>
        ) : null}
        {editable ? (
          <div data-fx-name="edit" className={usersClasses.editButton}>
            <CardEditLink
              text={<Icon outlined className="font-24 text-white user-select-none" type="edit" />}
              to={url({ route: 'editUser', id: String(userId) })}
            />
          </div>
        ) : null}
        <div className="d-flex pt-3">
          <Avatar
            src={avatarUrl}
            tooltip={tooltip}
            alt={`${fullName}'s Avatar`}
            data-fx-name="avatar"
          />
          <CardInfo className="ml-3 d-flex align-items-center" data-fx-name="cardInfo">
            <div>
              {firstName || lastName ? (
                <div data-fx-name="fullName">
                  <CardTitle className="font-weight-normal" text={fullName} />
                </div>
              ) : null}

              {title ? (
                <CardSubTitle className={'font-11'} text={title} />
              ) : (
                <CardSubTitle
                  className={'font-11 font-weight-light text-gray-500 font-italic'}
                  text={'No title'}
                />
              )}
              {officeNumber ? <PhoneNumber text={officeNumber} /> : null}

              {children}
            </div>
          </CardInfo>
        </div>
        <div className={`${styles.emailRow} pl-2 pt-1`}>
          {!companyUser?.hasDigestReport ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip data-fx-name="tooltip">
                  Not receiving any RevenueCloudFX digest emails (leads/companies/calls)
                </Tooltip>
              }
            >
              <Svg
                src={iconNotificationAlertSvg}
                title={'Not receiving digest reports'}
                className={styles.iconNotif}
                data-fx-name="monthlyReportIcon"
              />
            </OverlayTrigger>
          ) : null}
          {email ? (
            <EmailAddress
              className={'font-12 text-gray-600'}
              email={email}
              data-fx-name="emailAddress"
            />
          ) : null}
        </div>
        <CardInfo>
          {showToggles ? (
            <>
              <hr className="my-3" />
              <div className="py-2 px-4">
                {roles.map(({ name, key }) => {
                  return (
                    <Checkbox
                      id={`user-${userId}-${key}`}
                      key={`user-${userId}-${key}`}
                      checked={companyUser[key]}
                      round
                      onChange={() => onMutate(key, companyUser)}
                      size={companyUser[key] ? 'md' : 'sm'}
                      disabled={!isActive}
                      name={name}
                      label={name}
                      labelClassname="font-13 mb-1"
                      wrapperClassname="pl-0"
                      data-fx-name={_.camelCase(name)}
                    />
                  );
                })}
              </div>
              <hr className="my-3" />
            </>
          ) : null}
          <div className="pb-2">
            <div className="text-gray-500 font-11">
              {lastActiveAt ? (
                <div data-fx-name="lastActive">
                  <b>Last Login:</b>{' '}
                  {new Date(lastActiveAt).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </div>
              ) : null}

              {lastReportSent ? (
                <div>
                  <b>Last Report Sent:</b>{' '}
                  {new Date(lastReportSent).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </div>
              ) : null}
              {lastActiveUpdatedByUserName ? (
                <div data-fx-name="updatedByName">
                  <b>{isActive ? 'Added' : 'Deactivated'} by: </b>
                  <span>{_.startCase(_.toLower(lastActiveUpdatedByUserName))}</span>
                </div>
              ) : null}
              {lastActiveUpdatedAtDate ? (
                <div data-fx-name="updatedOn">
                  <b>Status updated:</b> {lastActiveUpdatedAtDate}
                </div>
              ) : null}
              {
                canViewAs ? (
                  <Button
                    variant="primary"
                    text="View As..."
                    icon="visibility"
                    iconClassName="font-16"
                    className={usersClasses.viewAsButton}
                    type="button"
                    onClick={onViewAs}
                    outline
                  />
                ): null
              }
            </div>
          </div>
        </CardInfo>
      </Card.Body>
    </Card>
  );
};

export default UserCard;
