import React from 'react';
import { Accordion, Typography } from '@webfx/core-web';
import { Container, Row, Col } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import styles from './FAQ.module.css';

const RenderQA = (options) => {
  const nodes = [];

  options.map((value, index) => {
    let answer = value.answer;
    if (typeof answer === 'string') {
      answer = <Typography variant={'p'}>{answer}</Typography>;
    }

    nodes.push(
      <div key={index}>
        <Accordion
          collapsed
          icon={'help'}
          title={value.question}
          className={styles.accordionSpacer}
          iconClassName={styles.accordionHeaderIcon}
        >
          {answer}
        </Accordion>
      </div>
    );
  });

  return nodes;
};

const FAQ = ({
  title = '',
  subTitle = '',
  options = [],
  thumbnail = '',
  centerHeader = false,
  onThumbnailClick = () => {},
}) => {
  return (
    <Container>
      <div
        className={classnames(styles.headContainer, centerHeader && 'text-center')}
        data-fx-name="header"
      >
        <Typography variant={'h3'} className={styles.title} data-fx-name="title">
          {title}
        </Typography>
        <Typography variant={'subtitle1'} className={styles.subTitle} data-fx-name="subTitle">
          {subTitle}
        </Typography>
      </div>
      <Row>
        {!isEmpty(thumbnail) && (
          <Col className={'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'}>
            <div
              onClick={() => onThumbnailClick()}
              className={styles.thumbnailLink}
              data-fx-name="thumbnail"
            >
              {thumbnail}
            </div>
          </Col>
        )}

        {!isEmpty(options) && (
          <Col
            className={'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'}
            data-fx-name="faqsSection"
          >
            {RenderQA(options)}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default FAQ;
